import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as M from 'materialize-css/dist/js/materialize';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit, AfterViewInit {
  elementModal;
  modalInstance;
  promoForm: any;
  submitted: boolean;
  sent: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.promoForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngAfterViewInit(): void {
    this.elementModal = document.querySelectorAll('.modal');
    this.modalInstance = M.Modal.init(this.elementModal);
    setTimeout(() => {
      this.modalInstance[0].open();
    }, 1000)
  }


  closeModal(): void {
    this.modalInstance[0].close();
  }

  sendEmail (): void {
    console.log('wut');
    this.submitted = true;
    if (this.promoForm.invalid) {
      return;
    }
    console.log(this.promoForm.value.name, this.promoForm.value.email);
    this.emailService.sendDeal(this.promoForm.value.email, this.promoForm.value.name).subscribe(() => {
      this.sent = true;
      this.submitted = false;
      this.promoForm.reset();
      this.modalInstance.close();
    })
  }

}
