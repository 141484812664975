import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  public constructor(private http: HttpClient) { }

  // API_URL: String = 'https://developer-notes-api.herokuapp.com/api';
  API_URL = 'https://api.boomscript.com/';


  private getHeaderOptions(type): object {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': `${type}`,
      })
    };
    return httpOptions;
  }


  public getRequest(params) {
    const request = this.http.get(`${this.API_URL}${params}`, this.getHeaderOptions('application/json'));
    return request;
  }

  public putRequest(params, obj) {
    const request = this.http.put(`${this.API_URL}${params}`, obj, this.getHeaderOptions('application/json'));
    return request;
  }

  public deleteRequest(params) {
    const request = this.http.delete(`${this.API_URL}${params}`, this.getHeaderOptions('application/json'));
    return request;
  }

  public postRequest(params, obj) {
    const request = this.http.post(`${this.API_URL}${params}`, obj, this.getHeaderOptions('application/json'));
    return request;
  }
}
