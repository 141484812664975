import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";

@Injectable({
  providedIn: "root"
})
export class EmailService {
  constructor(private rest: RestService) { }

  sendEmail(emailObj) {
    emailObj.to = 'petemartinez58@gmail.com';

    console.log(emailObj);
    const request = this.rest.postRequest('api/send-email-gmail/basic', emailObj);
    return request;
  }

  sendDeal(email, name) {
    const emailObj = {
      name,
      email,
      toEmail: 'petemartinez58@gmail.com',
      subject: 'Someone Signed up for the promotion!',
      body: `${name} signed up for the Guitar Giveaway.`
    };
    const request = this.rest.postRequest('send-email-gmail', emailObj);
    return request;
  }
}
