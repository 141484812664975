import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import * as M from 'materialize-css/dist/js/materialize';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, AfterViewInit {
  recentNotes: any;
  modalInstance: any;
  carouselInstance: any;
  elementCarousel: any;
  elementModal: any;
  options: Object;

  constructor() {
  }

  public ngOnInit (): void {
    console.log('init')

  }

  public ngAfterViewInit(): void {
    console.log('after init')
    this.elementCarousel = document.querySelectorAll('.carousel');
    this.carouselInstance = M.Carousel.init(this.elementCarousel, this.options);
  }

  public carouselLeft (): void {
    this.carouselInstance[0].prev();
  }

  public carouselRight (): void{
    this.carouselInstance[0].next();
  }

}
