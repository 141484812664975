import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { LessonsComponent } from './lessons/lessons.component';
import { ModalComponent } from './modal/modal.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'lessons', component: LessonsComponent},
    {path: 'contact', component: ContactComponent},
    {path: '**', component: NotFoundComponent}
]

@NgModule({
  declarations: [
  NotFoundComponent,
  HomeComponent,
  ContactComponent,
  LessonsComponent,
  ModalComponent
],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot()
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
